import React, { Component } from "react";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import * as AppConstant from "../../AppConstant";

import history from "./history";
import Header from "../Header";
import Footer from "../Footer";

import TransparentLoader from "../../loader/TransparentLoader";
import { withSuspense } from "../../hoc/withSuspense";

const Home = withSuspense(
  React.lazy(() => import("./../../Home/Home")),
  <TransparentLoader />
);

const Main = withSuspense(
  React.lazy(() => import("./../../About/Main")),
  <TransparentLoader />
);
const Product = withSuspense(
  React.lazy(() => import("./../../Product/Product")),
  <TransparentLoader />
);
const ProductDetail = withSuspense(
  React.lazy(() => import("./../../Product/ProductDetail")),
  <TransparentLoader />
);
const NotFound = withSuspense(
  React.lazy(() => import("./../../NotFound")),
  <TransparentLoader />
);
const ComingSoon = withSuspense(
  React.lazy(() => import("./../../ComingSoon")),
  <TransparentLoader />
);
const ContactBase = withSuspense(
  React.lazy(() => import("../../Contact/ContactBase")),
  <TransparentLoader />
);
const Communication = withSuspense(
  React.lazy(() => import("../../Communication/Communication")),
  <TransparentLoader />
);
const CareerBase = withSuspense(
  React.lazy(() => import("../../Career/CareerBase")),
  <TransparentLoader />
);
const Responsibility = withSuspense(
  React.lazy(() => import("../../Responsibility/Responsibility")),
  <TransparentLoader />
);
const Faqs = withSuspense(
  React.lazy(() => import("../../Faqs/Faqs")),
  <TransparentLoader />
);
const InvestorRelation = withSuspense(
  React.lazy(() => import("../../InvestorRelation/InvestorRelation")),
  <TransparentLoader />
);
const Sitemap = withSuspense(
  React.lazy(() => import("../../Sitemap/Sitemap")),
  <TransparentLoader />
);
const PrivacyPolicy = withSuspense(
  React.lazy(() => import("../../PrivacyPolicy/PrivacyPolicy")),
  <TransparentLoader />
);
const Power = withSuspense(
  React.lazy(() => import("../../Power/Power")),
  <TransparentLoader />
);
const RpsgGroup = withSuspense(
  React.lazy(() => import("../../RpsgGroup/RpsgGroup")),
  <TransparentLoader />
);

class Routes extends Component {
  render() {
    return (
      <div className="react-wrapper">
        <Router history={history} basename={AppConstant.BASE_PATH}>
          <Header />
          <ScrollToTop>
            <Switch>
              <Route path="/" component={Home} exact />
              <Route
                path="/about-rp-sanjiv-goenka-group"
                component={RpsgGroup}
              />
              <Route path="/about-us" component={Main} />
              <Route path="/product" component={Product} exact />
              <Route path="/product/:slug" component={ProductDetail} exact />
              <Route path="/responsibility" component={Responsibility} />
              <Route path="/communication" component={Communication} />
              <Route path="/media" component={ComingSoon} exact />
              <Route path="/career" component={CareerBase} />
              <Route path="/contact" component={ContactBase} />
              <Route path="/investor-relation" component={InvestorRelation} />
              <Route path="/faqs" component={Faqs} exact />
              <Route path="/sitemap" component={Sitemap} exact />
              <Route path="/privacy" component={PrivacyPolicy} exact />
              <Route path="/power" component={Power} exact />
              <Route exact path="/apply-online-4/">
                <Redirect to="/career/apply" />
              </Route>
              <Route exact path="/business-enquiry/">
                <Redirect to="/contact/business" />
              </Route>
              <Route exact path="/rubber-black/">
                <Redirect to="/product/rubber-black" />
              </Route>
              <Route exact path="/research-and-development/">
                <Redirect to="/about-us/research-and-development" />
              </Route>
              {/*    <Route component={NotFound} /> */}
              <Route from="*" render={() => <Redirect to="/" />} />
            </Switch>
          </ScrollToTop>
          <Footer />
        </Router>
      </div>
    );
  }
}

export default Routes;
