import React, { Fragment, useEffect, useState } from "react";
import Axios from "../../Services/Shared/Axios";
// import Logo2 from "../../Asset/psrg_logo_2.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faYoutube,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import * as AppConstant from "../AppConstant";
import {
  faMapMarkerAlt,
  faPhoneAlt,
  faFax,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

// const footerList = [
// 	// {
// 	// 	name: "home",
// 	// 	url: "/",
// 	// },
// 	// {
// 	// 	name: "about us",
// 	// 	url: "/about",
// 	// },
// 	// {
// 	// 	name: "products",
// 	// 	url: "/product",
// 	// },
// 	// {
// 	// 	name: "power",
// 	// 	url: "/power",
// 	// },
// 	// {
// 	// 	name: "Investor Relations",
// 	// 	url: "/investor-relation",
// 	// },
// 	// {
// 	// 	name: "responsibility & policies",
// 	// 	url: "/responsibility",
// 	// },
// 	/*{
// 	  name: "media",
// 	  url: "/media",
// 	},*/
// 	// {
// 	// 	name: "rp-sg group",
// 	// 	url: "/rpsg",
// 	// },
// 	// {
// 	// 	name: "r&d",
// 	// 	url: "/about/research",
// 	// },
// 	// {
// 	// 	name: "awards",
// 	// 	url: "/about/awards",
// 	// },
// 	/*{
// 	  name: "blog",
// 	  url: "url",
// 	},
// 	{
// 	  name: "news",
// 	  url: "url",
// 	},*/
// 	{
// 		name: "career",
// 		url: "/career",
// 	},
// 	/*{
// 	  name: "global presentation",
// 	  url: "url",
// 	},*/
// 	// {
// 	// 	name: "contact",
// 	// 	url: "/contact",
// 	// },
// 	{
// 		name: "faqs",
// 		url: "/faq",
// 	},
// 	/*{
// 	  name: "sitemap",
// 	  url: "/sitemap",
// 	},*/
// 	{
// 		name: "privacy policy",
// 		url: "/privacy",
// 	},
// ];

// const footerListmap = footerList.map((content) => {
// 	return (
// 		<li className="list-inline-item px-2 mb-2" key={content.name}>
// 			<Link
// 				className="text-white text-uppercase text-decoration-none"
// 				to={content.url}
// 			>
// 				<small>{content.name}</small>
// 			</Link>
// 		</li>
// 	);
// });

const Footer = () => {
  const [resultData, setResultData] = useState([]);
  useEffect(() => {
    Axios({
      method: "GET",
      url: "/contact",
    })
      .then((res) => {
        if (res.data) {
          setResultData(res.data);
        }
      })
      .catch((err) => {
        console.log("Err");
      });
  }, []);

  return (
    <footer className="container-fluid">
      <div className="row py-5 bg--darker text-light">
        <div className="col-12 col-xl-2 text-center copyrightCol">
          <Link to="/">
            <img
              className="img-fluid"
              src={AppConstant.LOGO_PCBL}
              alt="logo2"
            />
          </Link>

          <ul className="list-unstyled list-inline my-3 socialIcon">
            <li className="list-inline-item">
              <a
                className="btn text-light"
                target="_blank"
                href={AppConstant.FACEBOOK_URL}
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
            </li>
            <li className="list-inline-item">
              <a
                className="btn text-light"
                target="_blank"
                href={AppConstant.TWITTER_URL}
                rel="noopener noreferrer"
              >
                {/* <FontAwesomeIcon icon={faTwitter} /> */}
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 16 16">
                  <g id="Twitter_New" data-name="Twitter New" transform="translate(-9.652 -9.456)">
                    <path id="Path_9098" data-name="Path 9098" d="M270.282,259.467l5.957-6.775h-1.412l-5.176,5.882-4.131-5.882h-4.763l6.245,8.9-6.245,7.1h1.412l5.463-6.212L272,268.692h4.763l-6.479-9.225Zm-7.6-5.735h2.172l9.981,13.971h-2.174Z" transform="translate(-251.106 -243.236)" fill="#fff" />
                  </g>
                </svg>

              </a>
            </li>
            <li className="list-inline-item">
              <a
                className="btn text-light"
                target="_blank"
                href={AppConstant.LINKEDIN_URL}
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </li>
            <li className="list-inline-item">
              <a
                className="btn text-light"
                target="_blank"
                href={AppConstant.YOUTUBE_URL}
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faYoutube} />
              </a>
            </li>
          </ul>
          <small className="">
            {new Date().getFullYear()} &copy; PCBL All Rights Reserved
          </small>
        </div>

        <div className="col-12 col-xl-10 footerMenu">
          <div className="row">
            {resultData &&
              resultData.ContactOfficeContent &&
              resultData.ContactOfficeContent.map((data) => {
                return (
                  data.id === 1 &&
                  data.ContactContent &&
                  data.ContactContent.map((cdata, i) => {
                    return (
                      cdata.isFooter === true && (
                        <div className="col-md-6 col-lg-3" key={i}>
                          <h5 className="text-uppercase footer--heading position-relative pb-4">
                            {cdata.Heading}
                          </h5>
                          <div className="mb-3">PCBL Chemical Limited</div>
                          <div className="mb-1">
                            {cdata.Address && (
                              <div className="d-flex">
                                <FontAwesomeIcon
                                  className="text-danger"
                                  icon={faMapMarkerAlt}
                                />
                                <small className="pl-2">{cdata.Address}</small>
                              </div>
                            )}
                          </div>

                          {cdata.Phone && (
                            <div className="mb-1">
                              <FontAwesomeIcon
                                className="text-danger"
                                icon={faPhoneAlt}
                              />
                              <small className="pl-2">
                                <a
                                  className="text-decoration-none"
                                  href={"tel:" + cdata.Phone}
                                >
                                  {cdata.Phone}
                                </a>
                              </small>
                            </div>
                          )}

                          {cdata.Fax && (
                            <div className="mb-1">
                              <FontAwesomeIcon
                                className="text-danger"
                                icon={faFax}
                              />
                              <small className="pl-2">{cdata.Fax}</small>
                            </div>
                          )}

                          {cdata.Email && (
                            <div className="mb-1">
                              <FontAwesomeIcon
                                className="text-danger"
                                icon={faEnvelope}
                              />
                              <small className="pl-2">
                                <a
                                  className="text-decoration-none"
                                  href={"mailto:" + cdata.Email}
                                >
                                  {cdata.Email}
                                </a>
                              </small>
                            </div>
                          )}

                          {cdata.AdditionalEmail && (
                            <div className="mb-1">
                              <FontAwesomeIcon
                                className="text-danger"
                                icon={faEnvelope}
                              />
                              <small className="pl-2">
                                <a
                                  className="text-decoration-none"
                                  href={"mailto:" + cdata.AdditionalEmail}
                                >
                                  {cdata.AdditionalEmail}
                                </a>
                              </small>
                            </div>
                          )}
                        </div>
                      )
                    );
                  })
                );
              })}

            {resultData &&
              resultData.ContactOfficeContent &&
              resultData.ContactOfficeContent.map((data) => {
                return data.id === 5 ? (
                  data &&
                    data.ContactContent &&
                    data.ContactContent[0] &&
                    data.ContactContent[0].isFooter === true ? (
                    <div className="col-md-6 col-lg-3" key={data.id}>
                      <h5 className="text-uppercase footer--heading position-relative pb-4">
                        {data.ContactContent[0].Heading}
                      </h5>
                      <div className="mb-3">PCBL Chemical Limited</div>
                      <div className="mb-1">
                        {data.ContactContent[0].Address && (
                          <div className="d-flex">
                            <FontAwesomeIcon
                              className="text-danger"
                              icon={faMapMarkerAlt}
                            />
                            <small className="pl-2">
                              {data.ContactContent[0].Address}
                            </small>
                          </div>
                        )}
                      </div>

                      {data.ContactContent[0].Phone && (
                        <div className="mb-1">
                          <FontAwesomeIcon
                            className="text-danger"
                            icon={faPhoneAlt}
                          />
                          <small className="pl-2">
                            <a
                              className="text-decoration-none"
                              href={"tel:" + data.ContactContent[0].Phone}
                            >
                              {data.ContactContent[0].Phone}
                            </a>
                          </small>
                        </div>
                      )}

                      {data.ContactContent[0].Fax && (
                        <div className="mb-1">
                          <FontAwesomeIcon
                            className="text-danger"
                            icon={faFax}
                          />
                          <small className="pl-2">
                            {data.ContactContent[0].Fax}
                          </small>
                        </div>
                      )}

                      {data.ContactContent[0].Email && (
                        <div className="mb-1">
                          <FontAwesomeIcon
                            className="text-danger"
                            icon={faEnvelope}
                          />
                          <small className="pl-2">
                            <a
                              className="text-decoration-none"
                              href={"mailto:" + data.ContactContent[0].Email}
                            >
                              {data.ContactContent[0].Email}
                            </a>
                          </small>
                        </div>
                      )}

                      {data.ContactContent[0].AdditionalEmail && (
                        <div className="mb-1">
                          <FontAwesomeIcon
                            className="text-danger"
                            icon={faEnvelope}
                          />
                          <small className="pl-2">
                            <a
                              className="text-decoration-none"
                              href={
                                "mailto:" +
                                data.ContactContent[0].AdditionalEmail
                              }
                            >
                              {data.ContactContent[0].AdditionalEmail}
                            </a>
                          </small>
                        </div>
                      )}

                      {data &&
                        data.ContactContent &&
                        data.ContactContent[1] &&
                        data.ContactContent[1].isFooter === true ? (
                        <Fragment>
                          <div className="mb-1">
                            {data.ContactContent[1].Address && (
                              <div className="d-flex">
                                <FontAwesomeIcon
                                  className="text-danger"
                                  icon={faMapMarkerAlt}
                                />
                                <small className="pl-2">
                                  {data.ContactContent[1].Address}
                                </small>
                              </div>
                            )}
                          </div>

                          {data.ContactContent[1].Phone && (
                            <div className="mb-1">
                              <FontAwesomeIcon
                                className="text-danger"
                                icon={faPhoneAlt}
                              />
                              <small className="pl-2">
                                <a
                                  className="text-decoration-none"
                                  href={"tel:" + data.ContactContent[1].Phone}
                                >
                                  {data.ContactContent[1].Phone}
                                </a>
                              </small>
                            </div>
                          )}

                          {data.ContactContent[1].Fax && (
                            <div className="mb-1">
                              <FontAwesomeIcon
                                className="text-danger"
                                icon={faFax}
                              />
                              <small className="pl-2">
                                {data.ContactContent[1].Fax}
                              </small>
                            </div>
                          )}

                          {data.ContactContent[1].Email && (
                            <div className="mb-1">
                              <FontAwesomeIcon
                                className="text-danger"
                                icon={faEnvelope}
                              />
                              <small className="pl-2">
                                <a
                                  className="text-decoration-none"
                                  href={
                                    "mailto:" + data.ContactContent[1].Email
                                  }
                                >
                                  {data.ContactContent[1].Email}
                                </a>
                              </small>
                            </div>
                          )}

                          {data.ContactContent[1].AdditionalEmail && (
                            <div className="mb-1">
                              <FontAwesomeIcon
                                className="text-danger"
                                icon={faEnvelope}
                              />
                              <small className="pl-2">
                                <a
                                  className="text-decoration-none"
                                  href={
                                    "mailto:" +
                                    data.ContactContent[1].AdditionalEmail
                                  }
                                >
                                  {data.ContactContent[1].AdditionalEmail}
                                </a>
                              </small>
                            </div>
                          )}
                        </Fragment>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                );
              })}

            {resultData &&
              resultData.ContactOfficeContent &&
              resultData.ContactOfficeContent.map((data, i) => {
                return (
                  data.id === 2 && (
                    <div className="col-md-6 col-lg-3" key={i}>
                      <h5 className="text-uppercase footer--heading position-relative pb-4">
                        {data.Heading}
                      </h5>
                      {data &&
                        data.ContactContent &&
                        data.ContactContent.map((cdata) => {
                          return (
                            cdata.isFooter === true && (
                              <Fragment key={cdata.id}>
                                <div className="mb-2">{cdata.Heading}</div>
                                <div className="mb-2">
                                  <FontAwesomeIcon
                                    className="text-danger"
                                    icon={faEnvelope}
                                  />
                                  <small className="pl-2">
                                    <a
                                      className="text-decoration-none"
                                      href={"mailto:" + cdata.Email}
                                    >
                                      {cdata.Email}
                                    </a>
                                  </small>
                                </div>
                              </Fragment>
                            )
                          );
                        })}
                    </div>
                  )
                );
              })}
          </div>
        </div>
      </div>

      <div className="row bg--purple">
        <ul className="list-unstyled col-xl-8 m-auto py-3 list-inline text-center">
          <li className="list-inline-item px-2 mb-2">
            <Link className="text-white text-decoration-none" to={"/career"}>
              <small>{"Career"}</small>
            </Link>
          </li>
          <span className="text-white">|</span>
          <li className="list-inline-item px-2 mb-2">
            <Link className="text-white text-decoration-none" to={"/faqs"}>
              <small>{"FAQs"}</small>
            </Link>
          </li>
          <span className="text-white">|</span>
          <li className="list-inline-item px-2 mb-2">
            <Link className="text-white text-decoration-none" to={"/privacy"}>
              <small>{"Privacy Policy"}</small>
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
